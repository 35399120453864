<div class="wrapper" id="sidebar" style="height: auto; overflow: hidden;cursor: pointer;">
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-menu-wrapper">
      <div class="sidebar-header">
        <div>
          <a href="javascript:" (click)="navigateToOuk()">
            <img src="../../assets/img/logo1-new.png" class="logo-icon" [ngStyle]="{ 'height.px': isExpanded ? 65 : 40 }" alt="logo icon">
            <!-- <img src="../../assets/img/logo2-new.png" class="logo-icon" [ngStyle]="{ 'height.px': isExpanded ? 65 : 40 }" alt="logo icon"> -->
          </a>

          <span (click)="closeLeftMenuforMobile()" class="d-md-none toggle-icon"
            style="position: absolute; top: 2% !important;; right:1% !important;">
            <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" fill="red" class="bi bi-x"
              viewBox="0 0 16 16">
              <path
                d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
            </svg>
          </span>
        </div>

      </div>
      <div class="wrapper" style="margin-top: 9.6vh">
        <ul class="metismenu" id="menu">
          <!-- <li style="padding: 0px;"  *ngIf="roleId==1 || roleId ==2||roleId==4 || roleId ==2080|| roleId ==2097||roleId==5 || roleId ==2093||roleId==2098"
            [ngClass]="{'parent': true, 'mm-active': selectedMenuItem === 'Dashboard', 'inactive': lastSelectedMenuItem === 'Dashboard' && selectedMenuItem !== 'Dashboard'}">
            <a [routerLink]="['/HOME/dashboard']" (click)="navigate('','Dashboard')"><span class="icon">
                <svg width="15" height="20" fill="#A3AED0" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
                  <path
                    d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
                </svg></span>&nbsp;&nbsp;<span class="text">Dashboard</span></a>
          </li> -->
          <li style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" *ngIf="isShow == 'true'"
            [ngClass]="{'parent': true, 'mm-active': currentMenu === 'Dashboard', 'inactive': currentMenu  != 'Dashboard'}">
            <a [routerLink]="['/HOME/dashboard']" (click)="navigate('','Dashboard')"
              [attr.title]="isExpanded ? '' : 'Dashboard'">
              <span class="icon menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#037b90" height="2.2rem" viewBox="0 0 17 17">
                  <path
                    d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
                </svg></span><span *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">Dashboard</span>
            </a>
          </li>
          <li style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }"
            *ngIf="isShow == 'false' && roleId==3"
            [ngClass]="{'parent': true, 'mm-active': currentMenu === 'Personal Details Registration', 'inactive': currentMenu != 'Personal Details Registration'}">
            <a [routerLink]="['/HOME/components/prnlDetailsRegForm']" (click)="navigate('','Dashboard')"
              [attr.title]="isExpanded ? '' : 'Personal Details Registration'">
              <span class="icon menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#037b90" height="2.2rem" viewBox="0 0 17 17">
                  <path
                    d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
                </svg></span><span *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">Personal Details Registration</span></a>
          </li>
          <!-- <li *ngIf="roleId ==3" style="padding: 0px;" 
            [ngClass]="{'parent': true, 'active': selectedMenuItem === 'Home', 'inactive': lastSelectedMenuItem === 'Home' && selectedMenuItem !== 'Home'}">
            <a *ngIf="tenantCode !='88968955'" [routerLink]="['/HOME/stuHome']" (click)="navigate('','Home')"><span class="icon">
                <i class="fa fa-home" aria-hidden="true"></i>
              </span>&nbsp;&nbsp;<span class="text">Home</span></a>
          </li> -->
          <li style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" class="parent ">
            <a (click)="SearchMenuNav()" [attr.title]="isExpanded ? '' : 'SMHR'">
              <span class="icon menu-icon"><i class="fas fa-search"></i></span><span
                *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">Menu Search</span></a>
          </li>
          <li *ngIf="roleId==3" style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" class="parent ">
            <a (click)="mydocuments()" [attr.title]="isExpanded ? '' : 'SMHR'">
              <span class="icon menu-icon"><i class="fas fa-file"></i></span><span
                *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">My Doucments</span></a>
          </li>
          <li style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" class="parent ">
            <a *ngIf="tenantCode !='88968955'" (click)="smartHr(1)" [attr.title]="isExpanded ? '' : 'SMHR'">
              <span class="icon menu-icon"><i class="fas fa-users"></i></span><span
                *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">SMHR</span></a>
          </li>

          <li style="padding: 0px;" *ngIf="!isApplicant" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" class="parent ">
            <a [attr.title]="isExpanded ? '' : 'SMHR'">
              <span class="icon menu-icon"><i class="fas fa-users"></i></span><span
                *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">Library</span></a>
            <div class="wrapper">
              <ul style="padding: 0px;" class="style-2 shadow  nav-active2 parent">
                <li  class="parent "><a 
                    (click)="navigateToLibraryUrl('http://elibrary.ouk.ac.ke:8080/')">KOHA OUK Library</a>
                </li>
                <li  class="parent "><a 
                    (click)="navigateToLibraryUrl('https://elibrary.ouk.ac.ke/')">OUK OPAC Library</a>
                </li>
                <li *ngIf="roleId==3" class="parent "><a 
                    (click)="navigateToLibraryUrl('https://app.myloft.xyz/user/login?institute=clj72q7ocr9dq0d992icc9egm')">My
                    LOFT OUK library </a>
                </li>
                <li *ngIf="roleId==1" class="parent "><a 
                    (click)="navigateToLibraryUrl('https://admin.myloft.xyz')">My LOFT OUK library
                  </a>
                </li>
              </ul>
            </div>
          </li>

          <li style="padding: 0px;" [ngStyle]="{ 'margin-bottom': isExpanded ? '' : '1rem' }" class="parent "
            *ngFor='let item of menus'
            [ngClass]="{'parent': true, 'active': item.Name === currentMenu, 'inactive': item.Name === !currentMenu}">
            <a [ngClass]="item.childs.length > 0 ? 'has-arrow' : ''" (click)="navigate(item.path,item)"
              [attr.title]="isExpanded ? '' : item.Name">
              <span class="icon menu-icon" [attr.href]="'#'+getId(item.Name)">
                <i class={{item.CssClass}}></i>
              </span>
              <span *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">{{item.Name}}</span>
            </a>
            <div class="wrapper">
              <ul style="padding: 0px;" class="style-2 shadow  nav-active2 parent" *ngIf="item.childs.length"
                [attr.id]="getId(item.Name)">
                <li class="parent " *ngFor="let subMenu of item.childs"><a
                    [ngClass]="subMenu.childs.length > 0 ? 'has-arrow' : ''"
                    (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}}</a>
                  <div class="wrapper">
                    <ul class="style-3 shadow  nav-active3" *ngIf='subMenu.childs.length'>
                      <li class="parent " *ngFor="let menu of subMenu.childs" style="cursor: pointer;"><a
                          [ngClass]="menu.childs.length > 0 ? 'has-arrow' : ''"
                          (click)="navigate(menu.path,menu)">{{menu.Name}}</a>
                        <div class="wrapper">
                          <ul class="style-4 shadow  nav-active4" *ngIf='menu.childs.length'>
                            <li *ngFor="let fourthMenu of menu.childs" style="cursor: pointer;"><a
                                [ngClass]="fourthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                (click)="navigate(fourthMenu.path,fourthMenu)">{{fourthMenu.Name}}</a>
                              <div class="wrapper">
                                <ul class="style-5 shadow  nav-active5" *ngIf='fourthMenu.childs.length'>
                                  <li *ngFor="let fifthMenu of fourthMenu.childs" style="cursor: pointer;"><a
                                      [ngClass]="fifthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                      (click)="navigate(fifthMenu.path,fifthMenu)">{{fifthMenu.Name}}</a></li>
                                </ul>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </li>

                    </ul>
                  </div>
                </li>

              </ul>
            </div>
          </li>
          <!-- && isSubmit=='true' -->
          <li *ngIf="roleId =='3'" style="padding: 0px;" class="parent">
            <a (click)="userGuide()" [attr.title]="isExpanded ? '' : 'User Guide'">
              <span class="icon menu-icon">
                <svg xmlns="http://www.w3.org/2000/svg" fill="#037b90" height="2.2rem" viewBox="0 0 384 512">
                  <!--! Font Awesome Free 6.4.2 by @fontawesome - https://fontawesome.com License - https://fontawesome.com/license (Commercial License) Copyright 2023 Fonticons, Inc. -->
                  <path
                    d="M0 64C0 28.7 28.7 0 64 0H224V128c0 17.7 14.3 32 32 32H384V448c0 35.3-28.7 64-64 64H64c-35.3 0-64-28.7-64-64V64zm384 64H256V0L384 128z" />
                </svg></span> <span *ngIf="isExpanded">&nbsp;&nbsp;</span>
              <span *ngIf="isExpanded" class="text menu-name">User Guide</span>
            </a>
          </li>

        </ul>
      </div>
    </div>
  </div>

</div>


<!-- <div class="wrapper" style="height: auto; overflow: hidden;cursor: pointer;">
  <div class="sidebar-wrapper" data-simplebar="true">
    <div class="sidebar-header">
      <div>
        <img src="../../assets/img/samvaad_tutor_logo.png" class="logo-icon" alt="logo icon">
      </div>
    </div>
    <ul class="metismenu" id="menu">
      <li class="mm-active">
        <a [routerLink]="['/HOME/dashboard']">
          <div class="parent">
            <svg width="17" height="17" fill="#A3AED0" viewBox="0 0 17 17" xmlns="http://www.w3.org/2000/svg">
              <path
                d="M0.944444 9.44444H6.61111C7.13056 9.44444 7.55556 9.01944 7.55556 8.5V0.944444C7.55556 0.425 7.13056 0 6.61111 0H0.944444C0.425 0 0 0.425 0 0.944444V8.5C0 9.01944 0.425 9.44444 0.944444 9.44444ZM0.944444 17H6.61111C7.13056 17 7.55556 16.575 7.55556 16.0556V12.2778C7.55556 11.7583 7.13056 11.3333 6.61111 11.3333H0.944444C0.425 11.3333 0 11.7583 0 12.2778V16.0556C0 16.575 0.425 17 0.944444 17ZM10.3889 17H16.0556C16.575 17 17 16.575 17 16.0556V8.5C17 7.98056 16.575 7.55556 16.0556 7.55556H10.3889C9.86944 7.55556 9.44444 7.98056 9.44444 8.5V16.0556C9.44444 16.575 9.86944 17 10.3889 17ZM9.44444 0.944444V4.72222C9.44444 5.24167 9.86944 5.66667 10.3889 5.66667H16.0556C16.575 5.66667 17 5.24167 17 4.72222V0.944444C17 0.425 16.575 0 16.0556 0H10.3889C9.86944 0 9.44444 0.425 9.44444 0.944444Z" />
            </svg>

          </div>
          <div class="menu-title">Dashboard</div>
        </a>
      </li>
      <li>
        <a (click)="smartHr()">
          <div class="parent">
            <span class="icon"><i class="fas fa-users"></i></span>
          </div>
          <div class="menu-title">SMHR</div>
        </a>
      </li>

      <li style="padding: 0px;" class="parent" *ngFor='let item of menus'>
        <a [ngClass]="item.childs.length > 0 ? 'has-arrow' : ''" (click)="navigate(item.path,item)">
          <div class="parent">
            <span class="icon" [attr.href]="'#'+getId(item.Name)"><i class={{item.CssClass}}></i></span>
          </div>
          <div class="menu-title">{{item.Name}}</div>
        </a>
        <div class="wrapper">
          <ul style="padding: 0px;" class="style-1 shadow  nav-active2 parent" *ngIf="item.childs.length"
            [attr.id]="getId(item.Name)">
            <li class="parent" *ngFor="let subMenu of item.childs"><a
                [ngClass]="subMenu.childs.length > 0 ? 'has-arrow' : ''"
                (click)="navigate(subMenu.path,subMenu)">{{subMenu.Name}}</a>
              <div class="wrapper">
                <ul class="style-1 shadow  nav-active3" *ngIf='subMenu.childs.length'>
                  <li class="parent" *ngFor="let menu of subMenu.childs"><a
                      [ngClass]="menu.childs.length > 0 ? 'has-arrow' : ''"
                      (click)="navigate(menu.path,menu)">{{menu.Name}}</a>
                    <div class="wrapper">
                      <ul class="style-1 shadow  nav-active4" *ngIf='menu.childs.length'>
                        <li *ngFor="let fourthMenu of menu.childs"><a
                            [ngClass]="fourthMenu.childs.length > 0 ? 'has-arrow' : ''"
                            (click)="navigate(fourthMenu.path,fourthMenu)">{{fourthMenu.Name}}</a>
                          <div class="wrapper">
                            <ul class="style-1 shadow  nav-active4" *ngIf='fourthMenu.childs.length'>
                              <li *ngFor="let fifthMenu of fourthMenu.childs"><a
                                  [ngClass]="fifthMenu.childs.length > 0 ? 'has-arrow' : ''"
                                  (click)="navigate(fifthMenu.path,fifthMenu)">{{fifthMenu.Name}}</a></li>
                            </ul>
                          </div>
                        </li>
                      </ul>
                    </div>
                  </li>

                </ul>
              </div>
            </li>

          </ul>
        </div>
      </li>
    </ul>

    <div class="ms-1 sidebar-footer border-top pb-4 pt-3 px-4 w-100" style=" position: absolute; bottom: 0;">
      <div>
        <ul>
          <li>
            <a class="d-flex fw-500" href="payment.html" style="color: #8291C0;">
              <div class="parent">

                <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="currentColor"
                  class="bi bi-box-arrow-right" viewBox="0 0 16 16">
                  <path fill-rule="evenodd"
                    d="M10 12.5a.5.5 0 0 1-.5.5h-8a.5.5 0 0 1-.5-.5v-9a.5.5 0 0 1 .5-.5h8a.5.5 0 0 1 .5.5v2a.5.5 0 0 0 1 0v-2A1.5 1.5 0 0 0 9.5 2h-8A1.5 1.5 0 0 0 0 3.5v9A1.5 1.5 0 0 0 1.5 14h8a1.5 1.5 0 0 0 1.5-1.5v-2a.5.5 0 0 0-1 0v2z" />
                  <path fill-rule="evenodd"
                    d="M15.854 8.354a.5.5 0 0 0 0-.708l-3-3a.5.5 0 0 0-.708.708L14.293 7.5H5.5a.5.5 0 0 0 0 1h8.793l-2.147 2.146a.5.5 0 0 0 .708.708l3-3z" />
                </svg>


              </div>
              <div class="menu-title ms-3">Log Out</div>
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</div> -->




<!--Search Modal -->
<div class="modal fade" id="staticBackdrop" data-bs-backdrop="static" data-bs-keyboard="false" tabindex="-1"
  aria-labelledby="staticBackdropLabel" aria-hidden="true">
  <div class="modal-dialog modal-lg">
    <div class="modal-content">
      <div class="modal-header">
        <h5 class="modal-title" id="staticBackdropLabel">Menu Search</h5>
        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
      </div>
      <div class="modal-body">
        <div class="card" style="min-height: 25rem;">
          <div class="row pt-5 pb-5 ">
            <div class="col-md-8 mx-auto">
              <label class="control-label">Menu Search</label>
              <div class="inputGroupContainer">
                <div class="input-group justify-content-center">
                  <ng-multiselect-dropdown style="width:100%" [placeholder]="'Select Menu'" [(ngModel)]="selectedMenu"
                    [ngModelOptions]="{standalone: true}" [settings]="dropdownSettings" [data]="allChilds"
                    (onSelect)="onItemSelect($event)" (onDeSelect)="onItemDeSelect($event)">
                  </ng-multiselect-dropdown>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal" (click)="closeSearch()">Close</button>
      </div>
    </div>

  </div>
</div>